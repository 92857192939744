<template>
  <b-row
    style="height: 80vh"
    class="d-flex align-content-center justify-content-center"
  >
    <h1
      style="font-size: 80px; font-weight: 800; text-align: center; color: #0564f2 "
    >
      Welcom to system Gulftic
    </h1>
  </b-row>
</template>

<script>
// import axios from 'axios'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import StaticCards from './components/StaticCards.vue'

export default {
  components: {},
  data() {
    return {
      dashboard: {},
    }
  },
  mounted() {
    // this.getDahboard()
  },
  setup() {
    const resolveTransactinsStatusVariantAndIcon = status => {
      if (status === true) return { variant: 'light-success', icon: 'CheckCircleIcon' }

      return { variant: 'light-danger', icon: 'InfoIcon' }
    }

    const resolveTransactionsMethod = method => {
      if (method === 'received_money') return { variant: 'light-success', icon: 'ArrowDownRightIcon' }

      return { variant: 'light-info', icon: 'ArrowUpLeftIcon' }
    }

    return {
      resolveTransactinsStatusVariantAndIcon,
      resolveTransactionsMethod,
    }
  },
  methods: {
    // async getDahboard(params) {
    //   try {
    //     const { data } = await axios.get('statistics', { params })
    //     this.dashboard = data.data
    //   } catch (error) {
    //     if (error.response.status === 500) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Server Error',
    //           icon: 'BellIcon',
    //           variant: 'danger',
    //         },
    //       })
    //     }
    //   }
    // },
  },
}
</script>

<style></style>
